import React, { useEffect, useState } from "react";

import { Alert, Button, Card, Col, Form, Row } from "antd";
import themeConfig from "../../../configs/themeConfig";
import { Link, useHistory } from "react-router-dom";
import PageTitle from "../../../layout/components/content/page-title";

import kyc from "../../../assets/images/pages/kyc/kyc.svg";
import kycDark from "../../../assets/images/pages/kyc/kyc-dark.svg";

import pendingKYC from "../../../assets/images/pages/kyc/pending.svg";
import pendingKYCDark from "../../../assets/images/pages/kyc/pending-dark.svg";

import rejectKYC from "../../../assets/images/pages/kyc/reject.svg";
import rejectKYCDark from "../../../assets/images/pages/kyc/reject-dark.svg";
import { useSelector } from "react-redux";
import { API_URL } from "../../../api/config";
import { getAll } from "../../../utils/service";
import { useGetProfile } from "../../../UserProfileContext";

export default function Pages() {
  const theme = useSelector((state) => state.customise.theme);
  const [form] = Form.useForm();
  const history = useHistory();

  // Profile
  const dataProfile = useGetProfile();
  const [KYCStatus, setKYCStatus] = useState("");

  const getData = async () => {
    if (dataProfile?.kyc_verified) {
      history.push("/dashboard");
    } else if (dataProfile?.kyc_pending === true) {
      setKYCStatus("pending");
    } else if (dataProfile?.kyc_reject === true) {
      setKYCStatus("reject");
    } else {
      setKYCStatus("new");
    }
  };

  useEffect(() => {
    getData();
    document.title = "Verifikasi Berkas  - " + themeConfig.appname;
  }, [dataProfile]);
  return (
    <>
      <PageTitle pageTitle="Verifikasi Berkas" backButton={history.goBack} />

      <Row gutter={[32, 32]}>
        <Col lg={24} span={24}>
          {KYCStatus === "pending" ? (
            <Card className="vc-border-color-black-40 vc-text-center vc-py-48 vc-mb-24">
              <img
                src={theme == "light" ? pendingKYC : pendingKYCDark}
                alt="Background Image"
                className="vc-mb-24"
                width={"250px"}
              />

              <h3 className="vc-mb-sm-0 vc-my-24">Berkas sedang direview</h3>
              <p className="vc-mt-sm-0 vc-mt-8 vc-text-color-black-60 vc-mb-24">
                Kami sedang melakukan verifikasi terhadap berkasmu, silahkan
                tunggu maksimal 3 hari kerja
              </p>
              <Link to="/dashboard">
                <Button>Kembali ke Dashboard</Button>
              </Link>
            </Card>
          ) : KYCStatus === "reject" ? (
            <Card className="vc-border-color-black-40 vc-text-center vc-py-48 vc-mb-24">
              <div className="vc-text-center">
                <img
                  src={theme == "light" ? rejectKYC : rejectKYCDark}
                  alt="Background Image"
                  className="vc-mb-24"
                  width={"150px"}
                />
                <h3 className="vc-mb-sm-0 vc-my-24">Maaf Berkas Ditolak</h3>
                <p className="vc-mt-sm-0 vc-mt-8 vc-text-color-black-60 vc-mb-24">
                  Silahkan ulangi proses penginputan berkas
                </p>
              </div>

              {dataProfile?.kyc_reject_description !== "" && (
                <Row justify={"center"}>
                  <Col span={24} lg={10} className="vc-text-center">
                    <Alert
                      description={
                        <>
                          <p className="vc-mb-0">
                            <span className="vc-font-weight-600">
                              Catatan :{" "}
                            </span>
                            {dataProfile?.kyc_reject_description}
                          </p>
                        </>
                      }
                      type="error"
                      className="vc-w-100"
                    />
                  </Col>
                </Row>
              )}

              <div className="vc-text-center vc-mt-24">
                <Link to="/kyc-form">
                  <Button type="primary" className="vc-mr-8 vc-mb-sm-8">
                    Lengkapi Sekarang
                  </Button>
                </Link>
                <Link to="/dashboard">
                  <Button>Nanti</Button>
                </Link>
              </div>
            </Card>
          ) : KYCStatus === "new" ? (
            <Card className="vc-border-color-black-40 vc-text-center vc-py-48 vc-mb-24">
              <img
                src={theme == "light" ? kyc : kycDark}
                alt="Background Image"
                className="vc-mb-24"
                width={"150px"}
              />

              <h3 className="vc-mb-sm-0 vc-my-24">
                Selamat datang di H2H VCGamers
              </h3>
              <p className="vc-mt-sm-0 vc-mt-8 vc-text-color-black-60 vc-mb-24">
                Silahkan lengkapi berkas terlebih dahulu untuk mengakses semua
                fitur
              </p>

              <Link to="/kyc-form">
                <Button type="primary" className="vc-mr-8 vc-mb-sm-8">
                  Lengkapi Sekarang
                </Button>
              </Link>
              <Link to="/dashboard">
                <Button>Nanti</Button>
              </Link>
            </Card>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  );
}
