import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import {
  setGlobalState,
  getGlobalState,
} from "../../global-state/store.config";

export default function DriverJS(pages, steps, start = 0) {
  setGlobalState("coachmark", true);

  const storage = localStorage.getItem("vcg-mitra-coachmark-wmr");
  let coachmark = [];
  if (storage) {
    coachmark = JSON.parse(storage);
  }

  const handleCloseCoachmark = () => {
    driverObj.destroy();
    coachmark.push(pages);
    localStorage.setItem("vcg-mitra-coachmark-wmr", JSON.stringify(coachmark));
    setGlobalState("coachmark", false);
  };

  const driverObj = driver({
    showProgress: true,
    nextBtnText: "Lanjut",
    prevBtnText: "Kembali",
    doneBtnText: "Selesai",
    onCloseClick: handleCloseCoachmark,
    onDestroyed: handleCloseCoachmark,
    steps: steps,
  });

  if (!coachmark?.includes(pages)) {
    setGlobalState("coachmark", true);
    driverObj.drive(start);
  } else {
    setGlobalState("coachmark", false);
  }
}
