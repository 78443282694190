import React, { useEffect, useState } from "react";

import { Col, Row, Button, Alert } from "antd";
import { Buy, Home, Paper, Ticket, User } from "react-iconly";
import { Link } from "react-router-dom";
import { getAll } from "../../../../utils/service";
import { API_URL } from "../../../../api/config";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";

export default function BottomMenu(props) {
  const { active } = props;

  const [ticketData, setTicketData] = useState(0);

  const getTicketSummary = async () => {
    const res = await getAll(API_URL.ticket + "/indicator");
    if (res?.status === 200) {
      setTicketData(res?.data?.data);
    }
  };

  useEffect(() => {
    getTicketSummary();
  }, []);

  const [isScrolling, setIsScrolling] = useState(false);

  // useEffect(() => {
  //   let timeout;

  //   const handleScroll = () => {
  //     setIsScrolling(true);
  //     clearTimeout(timeout);

  //     timeout = setTimeout(() => {
  //       setIsScrolling(false);
  //     }, 200);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <Row
      className="vc-app-menu-layout vc-m-0 vc-bg-color-black-10 vc-bg-color-dark-90"
      id={props.id}
    >
      <Col flex="1" className="vc-text-center">
        <Link
          to="/dashboard"
          className={
            active === "home"
              ? "vc-app-menu vc-text-color-primary-1"
              : "vc-app-menu vc-text-color-grey-1 vc-text-color-dark-10 "
          }
        >
          <Home set="curved" />
          <span className="vc-d-block">Beranda</span>
        </Link>
      </Col>
      <Col flex="1" className="vc-text-center">
        <Link
          to="/list-transaction"
          className={
            active === "transaction"
              ? "vc-app-menu vc-text-color-primary-1"
              : "vc-app-menu vc-text-color-grey-1 vc-text-color-dark-10 "
          }
        >
          <Paper set="curved" />
          <span className="vc-d-block">Transaksi</span>
        </Link>
      </Col>
      <Col flex="auto" className="vc-d-flex vc-d-flex-justify-center">
        <Link
          to="/product/data"
          className="vc-app-menu vc-d-flex-justify-center"
          style={{ display: "flex" }}
        >
          <div className="vc-floating-menu">
            <Buy set="curved" />
            <span className="vc-d-block">Beli</span>
          </div>
        </Link>
      </Col>
      <Col flex="1" className="vc-text-center">
        <Link
          to="/request-ticket/data"
          className={
            active === "ticket"
              ? "vc-app-menu vc-text-color-primary-1"
              : "vc-app-menu vc-text-color-grey-1 vc-text-color-dark-10 "
          }
          style={{ position: "relative" }}
        >
          {ticketData > 0 && (
            <RiCheckboxBlankCircleFill
              className="remix-icon"
              color="red"
              size={8}
              style={{
                position: "absolute",
                top: 0,
                left: "55%",
                right: "45%",
              }}
            />
          )}
          <Ticket set="curved" />
          <span className="vc-d-block">Bantuan</span>
        </Link>
      </Col>
      <Col flex="1" className="vc-text-center">
        <Link
          to="/profile"
          className={
            active === "profile"
              ? "vc-app-menu vc-text-color-primary-1"
              : "vc-app-menu vc-text-color-grey-1 vc-text-color-dark-10 "
          }
        >
          <User set="curved" />
          <span className="vc-d-block">Profil</span>
        </Link>
      </Col>
    </Row>
  );
}
