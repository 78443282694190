import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import VCGamers from "../../../../assets/images/logo/logo.webp";
import VCGamersDark from "../../../../assets/images/logo/logo-dark.webp";
import VCGamersRtl from "../../../../assets/images/logo/logo-rtl.webp";
import VCGamersRtlDark from "../../../../assets/images/logo/logo-rtl-dark.webp";
import { useLocation } from "react-router-dom";
import themeConfig from "../../../../configs/themeConfig.jsx";

export default function MenuLogo(props) {
  const location = useLocation();
  const currentUrl = location.pathname;
  const customise = useSelector((state) => state.customise);

  return (
    <div className="vc-header-logo vc-d-flex vc-align-items-end">
      <Link to="/dashboard" onClick={props.onClose}>
        {customise.direction == "rtl" ? (
          customise.theme == "light" ? (
            <img className="vc-logo" src={VCGamersRtl} alt="logo" />
          ) : (
            <img className="vc-logo" src={VCGamersRtlDark} alt="logo" />
          )
        ) : customise.theme == "light" ? (
          <img className="vc-logo" src={VCGamers} alt="logo" />
        ) : (
          <img className="vc-logo" src={VCGamersDark} alt="logo" />
        )}
      </Link>

      <span
        className="vc-font-weight-500 vc-text-color-primary-1 vc-mb-16 vc-ml-4 "
        style={{
          fontSize: "10px",
          letterSpacing: "-1.5",
        }}
      >
        {currentUrl.includes("/panel") ? "PANEL" : "MITRA"}
      </span>
    </div>
  );
}
