import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { IoShareOutline } from "react-icons/io5";
import { Alert, Col, Image, Row } from "antd";
import { MobileView } from "react-device-detect";
import AppIcon from "../../../../assets/images/logo/app-icon.webp";

export default function TopBar(props) {
  const {
    pageTitle,
    pageGreeting,
    leftButton,
    rightButton,
    theme = "default", // default & purple,
  } = props;

  var bgTheme = "";
  var textTheme = "";
  if (theme === "purple") {
    bgTheme = "vc-bg-color-primary-1";
    textTheme = "vc-text-color-black-0";
  } else {
    bgTheme = "vc-bg-color-black-0 vc-bg-color-dark-90";
    textTheme = "vc-text-color-black-90 vc-text-color-dark-10";
  }

  if (leftButton && rightButton) {
    textTheme += " vc-text-center";
  }

  const [isScrolling, setIsScrolling] = useState(false);

  // useEffect(() => {
  //   let timeout;

  //   const handleScroll = () => {
  //     setIsScrolling(true);
  //     clearTimeout(timeout);

  //     timeout = setTimeout(() => {
  //       setIsScrolling(false);
  //     }, 200);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [deviceType, setDeviceType] = useState(null);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      setDeviceType("windows-phone");
    }

    if (/android/i.test(userAgent)) {
      setDeviceType("android");
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType("ios");
    }
  }

  useEffect(() => {
    getMobileOperatingSystem();
  }, []);

  return (
    <MobileView>
      <Row className={"vc-topbar " + bgTheme} align={"middle"}>
        {leftButton && (
          <Col flex={"none"} className={textTheme}>
            {leftButton}
          </Col>
        )}
        {pageTitle && (
          <Col flex={"auto"}>
            {pageTitle && (
              <h4 className={"vc-topbar-title " + textTheme}>{pageTitle}</h4>
            )}
          </Col>
        )}
        {pageGreeting && (
          <Col flex={"none"}>
            {pageGreeting && (
              <h5 className={"vc-mb-0 " + textTheme}>{pageGreeting}</h5>
            )}
          </Col>
        )}
        {rightButton && (
          <Col
            flex={"none"}
            className={textTheme}
            style={{ marginLeft: "auto" }}
          >
            {rightButton}
          </Col>
        )}
      </Row>
    </MobileView>
  );
}
