import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Button, Badge, Row, Col, Dropdown, Divider, Avatar } from "antd";
import { Notification, TickSquare, CloseSquare, Danger } from "react-iconly";
import { Link } from "react-router-dom";

export default function HeaderNotifications() {
  const direction = useSelector((state) => state.customise.direction);

  const notificationMenu = (
    <div
      className="vc-border-radius vc-border-1 vc-border-color-black-40 vc-bg-black-0 vc-bg-dark-100 vc-border-color-dark-80 vc-pt-24 vc-pb-18 vc-px-18 vc-mt-18"
      style={{ width: 300 }}
    >
      <Row align="middle" justify="space-between" className="vc-mb-18">
        <Col className="h5 vc-text-color-black-100 vc-text-color-dark-10 vc-text-color-dark-0 vc-mr-64">
          Notifikasi
        </Col>
      </Row>

      <Divider className="vc-my-4" />

      <div
        className="vc-overflow-y-auto vc-px-10"
        style={{ maxHeight: 300, marginRight: -10, marginLeft: -10 }}
      >
        <Row
          align="middle"
          className="vc-p-8 vc-text-center"
          style={{ marginLeft: -10, marginRight: -10 }}
        >
          <Col span={24} className="vc-text-color-dark-10">
            Belum Ada Notifikasi
          </Col>
        </Row>
      </div>

      {/* <Divider className="vc-my-4" />

      <Button
        type="text"
        block
        ghost
        className="vc-text-color-primary-1 vc-text-color-dark-primary-2 vc-fill-primary-1 vc-fill-dark-primary-2 vc-hover-bg-primary-4 vc-hover-bg-dark-80 vc-mt-4"
        icon={
          <svg
            className="vc-mr-10"
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.8335 3.00004H14.1668V4.33337H12.8335V13C12.8335 13.1769 12.7633 13.3464 12.6382 13.4714C12.5132 13.5965 12.3436 13.6667 12.1668 13.6667H2.8335C2.65669 13.6667 2.48712 13.5965 2.36209 13.4714C2.23707 13.3464 2.16683 13.1769 2.16683 13V4.33337H0.833496V3.00004H4.16683V1.00004C4.16683 0.82323 4.23707 0.65366 4.36209 0.528636C4.48712 0.403612 4.65669 0.333374 4.8335 0.333374H10.1668C10.3436 0.333374 10.5132 0.403612 10.6382 0.528636C10.7633 0.65366 10.8335 0.82323 10.8335 1.00004V3.00004ZM11.5002 4.33337H3.50016V12.3334H11.5002V4.33337ZM5.50016 6.33337H6.8335V10.3334H5.50016V6.33337ZM8.16683 6.33337H9.50016V10.3334H8.16683V6.33337ZM5.50016 1.66671V3.00004H9.50016V1.66671H5.50016Z" />
          </svg>
        }
      >
        Clear all notifications
      </Button> */}
    </div>
  );

  return (
    <Col className="vc-d-flex-center vc-mr-8">
      <Button
        type="text"
        icon={
          <Link to="/notification">
            <Notification set="curved" className="vc-text-color-black-60" />
          </Link>
        }
      />
    </Col>
  );
}
