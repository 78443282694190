import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Button } from "antd";

import img from "../../../assets/images/pages/error/404.svg";
import themeConfig from "../../../configs/themeConfig";

export default function Error500() {
  return (
    <Row className="vc-bg-color-primary-4 vc-bg-color-dark-90 vc-text-center">
      <Col className="vc-error-content vc-py-32" span={24}>
        <Row className="vc-h-100" align="middle" justify="center">
          <Col>
            <div className="vc-position-relative vc-mt-sm-0 vc-mt-64 vc-mb-32">
              <div className="vc-error-content-circle vc-bg-dark-100"></div>

              <img
                className="vc-position-relative vc-d-block vc-m-auto"
                src={img}
                width={400}
                alt="500"
              />
            </div>

            <h1 className="vc-error-content-title vc-mb-sm-0 vc-mb-8 vc-font-weight-500">
              500 - Oops, Server Error!
            </h1>

            <p className="vc-mb-32 vc-p1-body">You can go back home.</p>

            <Link to="/dashboard">
              <Button type="primary">Back to Home</Button>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="vc-py-24">
        <p className="vc-mb-0 vc-badge-text">
          Copyright ©2023 - {new Date().getFullYear()} {themeConfig.company},
          All rights Reserved
        </p>
      </Col>
    </Row>
  );
}
