import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Image,
  Modal,
  Row,
  Skeleton,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { getAll } from "../../../utils/service";
import { API_URL } from "../../../api/config";
import { eraseCookie, getCookie, setCookie } from "../../../utils/cookie";
import { useGetProfile } from "../../../UserProfileContext";
import moment from "moment";
import "moment/locale/id";
import { useGlobalState } from "../../../utils/global-state/store.config";

export default function ModalOnboarding(props) {
  const [coachmark] = useGlobalState("coachmark");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getOnboarding = async () => {
    const res = await getAll(API_URL.onboarding);
    if (res?.status === 200) {
      setDataSource(res?.data?.data);
      if (res?.data?.data?.is_active === true) {
        setIsModalVisible(true);
      }
    }
    setIsLoading(false);
  };

  const handleDontShowAgain = (e) => {
    if (e.target.checked) {
      setCookie(`onboarding-${dataProfile?.id}`, true, 1);
    } else {
      eraseCookie(`onboarding-${dataProfile?.id}`);
    }
  };

  // Profile
  const dataProfile = useGetProfile();
  const [tempProfile, setTempProfile] = useState([]);

  const _ = require("lodash");

  const handleCheckCookie = () => {
    if (dataProfile && !_.isEqual(dataProfile, tempProfile)) {
      const cookie = getCookie(`onboarding-${dataProfile?.id}`);
      if (!cookie) {
        getOnboarding();
      }
      setTempProfile(dataProfile);
    } else {
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    if (coachmark === false) {
      handleCheckCookie();
    }
  }, [dataProfile, coachmark]);

  return (
    <>
      {props.type === "drawer" ? (
        <Drawer
          headerStyle={{ border: "none" }}
          visible={isModalVisible}
          onCancel={handleCancel}
          className="vc-wmr-bottom-sheet"
          height={"600px"}
          placement="bottom"
          closable={false}
          footerStyle={{ border: "none", padding: "16px" }}
          bodyStyle={{ padding: "16px" }}
          footer={
            <>
              <Row justify="between" span="24" align={"center"}>
                <Col span={24}>
                  <Checkbox onChange={handleDontShowAgain} className="vc-mb-16">
                    Jangan Tampilkan Lagi
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Button
                    type="primary"
                    key="back"
                    onClick={handleCancel}
                    className="vc-w-100"
                  >
                    Tutup
                  </Button>
                </Col>
              </Row>
            </>
          }
          closeIcon={
            <RiCloseLine
              className="remix-icon text-color-black-100"
              size={24}
            />
          }
        >
          {isLoading === true ? (
            <Skeleton active />
          ) : (
            <>
              <div>
                <div className="vc-mb-16">
                  <h4 className="vc-mb-8">Informasi</h4>
                  <p className="vc-mb-0 vc-text-color-grey-1">
                    {moment(dataSource.last_update)
                      .locale("id")
                      .format("DD MMM YYYY HH:mm:ss")}
                  </p>
                </div>
                {dataSource.image_url && (
                  <Image
                    style={{ aspectRatio: " 1 / 1", objectFit: "cover" }}
                    preview={false}
                    src={dataSource?.image_url}
                    className="vc-border-radius"
                    width={"100%"}
                    height={"200px"}
                    sizes="cover"
                    alt={"Onboarding"}
                  />
                )}
                {dataSource.content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataSource?.content,
                    }}
                    className="vc-p1-body vc-wysiwyg-output vc-mt-24 vc-wysiwyg-onboarding"
                  ></div>
                )}
              </div>
            </>
          )}
        </Drawer>
      ) : (
        <Modal
          headerStyle={{ border: "none" }}
          width={500}
          visible={isModalVisible}
          onCancel={handleCancel}
          className=""
          footer={
            <>
              <Row justify="between" span="24" align={"center"}>
                <Col>
                  <Checkbox onChange={handleDontShowAgain} className="vc-mt-8">
                    Jangan Tampilkan Lagi
                  </Checkbox>
                </Col>
                <Col flex="auto">
                  <Button type="text" key="back" onClick={handleCancel}>
                    Tutup
                  </Button>
                </Col>
              </Row>
            </>
          }
          closeIcon={
            <RiCloseLine
              className="remix-icon text-color-black-100"
              size={24}
            />
          }
        >
          {isLoading === true ? (
            <Skeleton active />
          ) : (
            <>
              <div>
                <div className="vc-mb-16">
                  <h4 className="vc-mb-8">Informasi</h4>
                  <p className="vc-mb-0 vc-text-color-grey-1">
                    {moment(dataSource.last_update)
                      .locale("id")
                      .format("DD MMM YYYY HH:mm:ss")}
                  </p>
                </div>

                {dataSource.image_url && (
                  <Image
                    style={{ aspectRatio: " 1 / 1", objectFit: "cover" }}
                    preview={false}
                    src={dataSource?.image_url}
                    className="vc-border-radius"
                    width={"100%"}
                    height={"200px"}
                    sizes="cover"
                    alt={"Onboarding"}
                  />
                )}
                {dataSource.content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataSource?.content,
                    }}
                    className="vc-p1-body vc-wysiwyg-output vc-mt-24 vc-wysiwyg-onboarding"
                  ></div>
                )}
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  );
}
