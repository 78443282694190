import React, { useEffect, useState } from "react";
import {
  RiArrowRightLine,
  RiArrowRightSLine,
  RiCloseLine,
  RiMore2Fill,
} from "react-icons/ri";
import { IoShareOutline } from "react-icons/io5";
import { Alert, Button, Col, Image, Row } from "antd";
import AppIcon from "../../../../assets/images/logo/app-icon.webp";

export default function AppBanner(props) {
  const [deviceType, setDeviceType] = useState(null);
  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      setDeviceType("windows-phone");
    }

    if (/android/i.test(userAgent)) {
      setDeviceType("android");
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType("ios");
    }
  }
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("PWA installed");
        } else {
          console.log("User declined PWA installation");
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    getMobileOperatingSystem();
  }, []);

  return (
    <>
      {deviceType === "ios" ? (
        <Alert
          onClose={props.onClose}
          message={
            <div className="vc-d-flex vc-align-items-center">
              <Image
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={AppIcon}
                className="vc-border-radius"
                width={"64px"}
                alt={"App Icon"}
              />
              <div className="vc-ml-8">
                <span>Akses feature VCGamers Mitra melalui Homescreen</span>
                {/* <span>
                  <IoShareOutline /> pada browser, lalu Tambah ke Home Screen.
                </span> */}
                <a
                  href="https://www.vcgamers.com/news/cara-instal-vcgamers-mitra-di-hp-android-dan-ios/"
                  target="_blank"
                  className="vc-ml-4 vc-text-color-dark-0 "
                  style={{ display: "inline-flex", alignItems: "center" }}
                >
                  <b>Selengkapnya </b>
                  <RiArrowRightLine
                    size={12}
                    className="vc-ml-4 vc-text-color-dark-0 "
                  />
                </a>
              </div>
            </div>
          }
          closable
          className="vc-w-100 vc-topbar-pwa vc-bg-color-primary-4 vc-bg-color-dark-primary vc-text-color-primary-1"
          closeIcon={
            <RiCloseLine size={24} className="vc-text-color-primary-1" />
          }
        />
      ) : (deviceType === "android" || deviceType === "windows-phone") &&
        isChrome ? (
        <Alert
          onClose={props.onClose}
          message={
            <div className="vc-d-flex vc-align-items-center">
              <Image
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={AppIcon}
                className="vc-border-radius"
                width={"64px"}
                alt={"App Icon"}
              />
              <div className="vc-ml-8 vc-w-100">
                <span>Akses feature VCGamers Mitra melalui Homescreen</span>
                {/* <a
                    href="https://www.vcgamers.com/news/cara-instal-vcgamers-mitra-di-hp-android-dan-ios/"
                    target="_blank"
                    className="vc-ml-4 vc-text-color-dark-0"
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <b>Selengkapnya </b>
                    <RiArrowRightLine size={12} className="vc-ml-4 vc-text-color-dark-0" />
                  </a> */}
              </div>
              <div className="vc-ml-8">
                <Button
                  type="primary"
                  size="small"
                  onClick={handleInstallClick}
                >
                  Install
                </Button>
              </div>
            </div>
          }
          closable
          className="vc-w-100 vc-topbar-pwa vc-bg-color-primary-4 vc-bg-color-dark-primary vc-text-color-primary-1"
          closeIcon={
            <RiCloseLine size={24} className="vc-text-color-primary-1" />
          }
        />
      ) : (
        (deviceType === "android" || deviceType === "windows-phone") &&
        !isChrome && (
          <Alert
            onClose={props.onClose}
            message={
              <div className="vc-d-flex vc-align-items-center">
                <Image
                  style={{ aspectRatio: " 1 / 1" }}
                  preview={false}
                  src={AppIcon}
                  className="vc-border-radius"
                  width={"64px"}
                  alt={"App Icon"}
                />
                <div className="vc-ml-8">
                  <span>Akses feature VCGamers Mitra melalui Homescreen</span>
                  <a
                    href="https://www.vcgamers.com/news/cara-instal-vcgamers-mitra-di-hp-android-dan-ios/"
                    target="_blank"
                    className="vc-ml-4 vc-text-color-dark-0"
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <b>Selengkapnya </b>
                    <RiArrowRightLine
                      size={12}
                      className="vc-ml-4 vc-text-color-dark-0"
                    />
                  </a>
                </div>
              </div>
            }
            closable
            className="vc-w-100 vc-topbar-pwa vc-bg-color-primary-4 vc-bg-color-dark-primary vc-text-color-primary-1"
            closeIcon={
              <RiCloseLine size={24} className="vc-text-color-primary-1" />
            }
          />
        )
      )}
    </>
  );
}
