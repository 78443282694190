import { Col } from "antd";

export default function HeaderText({ name = "" }) {
  return (
    <Col xl={16} lg={14} className="vc-header-left-text vc-d-flex-center">
      <p className="vc-header-left-text-item vc-input-label vc-text-color-black-100 vc-text-color-dark-0 vc-ml-16 vc-mb-0">
        Selamat datang, {name} 👋
      </p>
    </Col>
  );
}
