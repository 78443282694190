import React, { createContext, useContext, useState, useEffect } from "react";
import { WEBSOCKET_API_URL } from "./api/config";

import { notification } from "antd";

import notifRingtone from "./assets/audio/ringtone.wav";
import { Bookmark, Game, InfoCircle, Notification, Wallet } from "react-iconly";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WebSocketNotifContext = createContext();

export const WebSocketNotifProvider = ({ children }) => {
  const history = useHistory();

  const [webSocket, setWebSocket] = useState([]);

  const websocketNotification = (token) => {
    var socket = new WebSocket(
      WEBSOCKET_API_URL.notification + "?token=" + token
    );

    socket.onmessage = function (event) {
      setWebSocket(event.data);
      openNotification(JSON.parse(event.data));
    };

    socket.onclose = function (e) {
      checkSession();
    };
    window.onbeforeunload = (event) => {
      socket.close();
    };
  };

  const handleNotificationClick = (clickable, url) => {
    if (clickable === true) {
      window.location.href = url;
    }
  };

  notification.config({
    maxCount: 3,
  });

  const openNotification = (data) => {
    const audio = new Audio(notifRingtone);
    audio.volume = 1;
    audio.play();

    if (data.message_type === 0) {
      // 0 = Transaction Success
      notification.open({
        message: <span data-title="trx-success-title">{data.title}</span>,
        description: (
          <span data-desc="trx-success-desc">{data.description}</span>
        ),
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <Bookmark size={24} className={`vc-text-color-success-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/detail-transaction?q=" + data.clickable_id
          ),
      });
    } else if (data.message_type === 1) {
      // 1 = Transaction Refunded
      notification.open({
        message: <span data-title="trx-refund-title">{data.title}</span>,
        description: (
          <span data-desc="trx-refund-desc">{data.description}</span>
        ),
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <Bookmark size={24} className={`vc-text-color-danger-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/detail-transaction?q=" + data.clickable_id
          ),
      });
    } else if (data.message_type === 2) {
      // 2 = Success Top Up
      notification.open({
        message: <span data-title="topup-success-title">{data.title}</span>,
        description: (
          <span data-desc="topup-success-desc">{data.description}</span>
        ),
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <Wallet size={24} className={`vc-text-color-success-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/topup/payment?q=" + data.clickable_id
          ),
      });
    } else if (data.message_type === 3) {
      // 3 = Topup Expired
      notification.open({
        message: <span data-title="topup-expired-title">{data.title}</span>,
        description: (
          <span data-desc="topup-expired-desc">{data.description}</span>
        ),
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <Wallet size={24} className={`vc-text-color-danger-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/topup/payment?q=" + data.clickable_id
          ),
      });
    } else if (data.message_type === 4) {
      // 4 = Reminder Saldo
      notification.open({
        message: <span data-title="reminder-title">{data.title}</span>,
        description: <span data-desc="reminder-desc">{data.description}</span>,
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <Notification size={24} className={`vc-text-color-warning-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
        onClick: () =>
          handleNotificationClick(data.is_clickable, "/topup/form"),
      });
    } else if (data.message_type === 5) {
      // 5 = Ticket Replied
      notification.open({
        message: <span data-title="ticket-title">{data.title}</span>,
        description: <span data-desc="ticket-desc">{data.description}</span>,
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <Wallet size={24} className={`vc-text-color-primary-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/request-ticket/detail?q=" + data.clickable_id
          ),
      });
    } else if (data.message_type === 6) {
      // 6 = KYC Verified
      notification.open({
        message: <span data-title="kyc-verified-title">{data.title}</span>,
        description: (
          <span data-desc="kyc-verified-desc">{data.description}</span>
        ),
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <Document size={24} className={`vc-text-color-success-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
      });
    } else if (data.message_type === 7) {
      // 7 = KYC Reject
      notification.open({
        message: <span data-title="kyc-reject-title">{data.title}</span>,
        description: (
          <span data-desc="kyc-reject-desc">{data.description}</span>
        ),
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <Document size={24} className={`vc-text-color-danger-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
        onClick: () => handleNotificationClick(true, "/kyc"),
      });
    } else if (data.message_type === 8) {
      // 8 = Price Update
      notification.open({
        message: <span data-title="price-update-title">{data.title}</span>,
        description: (
          <span data-desc="price-update-desc">{data.description}</span>
        ),
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <InfoCircle size={24} className={`vc-text-color-primary-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
      });
    } else if (data.message_type === 9) {
      // 9 = New Product
      notification.open({
        message: <span data-title="new-product-title">{data.title}</span>,
        description: (
          <span data-desc="new-product-desc">{data.description}</span>
        ),
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <Game size={24} className={`vc-text-color-primary-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/transaction-form?q=" + data.clickable_id
          ),
      });
    } else {
      notification.open({
        message: <span data-title="notif-title">{data.title}</span>,
        description: <span data-title="notif-desc">{data.description}</span>,
        placement: "topRight",
        duration: process.env.REACT_APP_DEVELOPMENT === "true" ? 0 : 4.5,
        icon: <InfoCircle size={24} className={`vc-text-color-info-1`} />,
        className: data.is_clickable === true && "vc-cursor-pointer",
      });
    }
  };

  const checkSession = () => {
    const storage = localStorage.getItem("loginSession");
    if (storage) {
      const token = JSON.parse(storage).accessToken;
      websocketNotification(token);
    }
  };
  // useEffect
  useEffect(() => {
    checkSession();
  }, []);

  return (
    <WebSocketNotifContext.Provider value={webSocket}>
      {children}
    </WebSocketNotifContext.Provider>
  );
};

export const useWebSocketNotif = () => {
  return useContext(WebSocketNotifContext);
};
