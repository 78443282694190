const themeConfig = {
  appname: "VCGamers",
  company: "PT. Sotta Teknologi Indonesia",
  version: "1.1.0",
  type:
    localStorage.getItem("vcg-sidebar-type") === "panel" ? "PANEL" : "MITRA",
  theme: localStorage.getItem("theme") === "dark" ? "dark" : "light",
  contentWidth: "full",
  sidebarCollapsed: false,
  sidebarCollapseButton: true,
  layout: "VerticalLayout",
  navigationFull: false,
  navigationBg: false,
  direction: "ltr",
  language: "id",
  whatsapp: "628159021021",
};

export default themeConfig;
