import { eraseCookie } from "./cookie";

export function autoLogout() {
  localStorage.removeItem("loginSession");
  localStorage.removeItem("permissions");
  localStorage.removeItem("recoveryEmail");
  localStorage.removeItem("countdown");
  localStorage.removeItem("countdown-otp");
  localStorage.removeItem("sendEmailVerif");
  localStorage.removeItem("vcg-sidebar-type");
  eraseCookie("mitra-vcgamers-session");
  window.location.href = "/login";
}
