import React, { createContext, useContext, useState, useEffect } from "react";
import { API_URL } from "./api/config";
import { getAll } from "./utils/service";
import { useHistory } from "react-router-dom";
import Error500 from "./view/pages/error/500";

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const history = useHistory();

  const [dataProfile, setDataProfile] = useState(null);

  const getProfile = async () => {
    const res = await getAll(API_URL.profile);
    if (res?.status === 200) {
      setDataProfile(res?.data?.data);
    }
  };

  const checkSession = () => {
    const storage = localStorage.getItem("loginSession");
    if (storage) {
      getProfile();
    }
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      checkSession();
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <UserProfileContext.Provider value={dataProfile}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useGetProfile = () => {
  return useContext(UserProfileContext);
};
