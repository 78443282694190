import { Col, Layout, Row } from "antd";

import themeConfig from "../../../configs/themeConfig.jsx";

export default function MenuFooter() {
  const { Footer } = Layout;

  return (
    <Footer className="vc-bg-color-black-10 vc-bg-color-dark-100">
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <p className="vc-badge-text vc-mb-0 vc-text-color-dark-30">
            Copyright ©2023 - {new Date().getFullYear()} {themeConfig.company},
            All rights Reserved
          </p>
        </Col>
      </Row>
    </Footer>
  );
}
