import React, { createContext, useContext, useState, useEffect } from "react";
import { WEBSOCKET_API_URL } from "./api/config";
import { notification } from "antd";
import { Bookmark, Notification, Wallet } from "react-iconly";

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [webSocket, setWebSocket] = useState(null);

  const checkSession = () => {
    const storage = localStorage.getItem("loginSession");
    if (storage) {
      const token = JSON.parse(storage).accessToken;
      websocketBalance(token);
    }
  };

  const websocketBalance = (token) => {
    var socket = new WebSocket(WEBSOCKET_API_URL.balance + "?token=" + token);

    socket.onopen = () => {
      // setWebSocket(socket);
    };

    socket.onmessage = function (event) {
      setWebSocket(event.data);
    };

    socket.onclose = function (event) {
      checkSession();
    };

    window.onbeforeunload = (event) => {
      socket.close();
    };
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <WebSocketContext.Provider value={webSocket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketBalance = () => {
  return useContext(WebSocketContext);
};
