import React, { Suspense, useEffect, useRef, useState } from "react";

import { Button, Card, Col, Form, Image, Row, Skeleton } from "antd";

import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";

import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiCheckboxCircleFill,
  RiInformationLine,
} from "react-icons/ri";

import { Link, useHistory } from "react-router-dom";

import TopBar from "../../../layout/components/content/topbar";
import { Graph, Notification, Plus, Star, Wallet } from "react-iconly";
import BottomMenu from "../../../layout/components/content/bottom-menu";
import { getAll, getPaginate } from "../../../utils/service";
import { API_URL } from "../../../api/config";

import { useWebSocketBalance } from "../../../WebSocketContext";
import { formatterNumber } from "../../../utils/input-number";
import SingleTransaction from "../../../layout/components/wmr/transaction";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SingleProduct from "../../../layout/components/wmr/product";
import { useGetProfile } from "../../../UserProfileContext";

import ImgMenuPricelist from "../../../assets/images/menu/menu-pricelist.webp";
import ImgMenuAturHargaJual from "../../../assets/images/menu/menu-selling-price.webp";
import ImgMenuBestPrice from "../../../assets/images/menu/menu-bestprice.webp";
import ImgMenuPembukuan from "../../../assets/images/menu/menu-pembukuan.webp";

import DriverJS from "../../../utils/coachmark/wmr/driver";
import HomeCoachmark from "../../../utils/coachmark/wmr/home.json";
import HomeCoachmarkUnverified from "../../../utils/coachmark/wmr/home-unverified.json";
import moment from "moment";

export default function DashboardMobile() {
  const [form] = Form.useForm();
  const history = useHistory();

  // Profile
  const dataProfile = useGetProfile();
  const [isCompleteMilestone, setIsCompleteMilestone] = useState(null);

  const handleCheckMilestone = () => {
    if (
      dataProfile?.has_pin === true &&
      // dataProfile?.kyc_verified === true &&
      dataProfile?.has_topup === true &&
      dataProfile?.has_purchase === true
    ) {
      setIsCompleteMilestone(true);
      DriverJS("dashboard", HomeCoachmark);
      getDataTransaksi();
      getTopProduct();
      getPendingTopup();
    } else {
      setIsCompleteMilestone(false);
      DriverJS("dashboard", HomeCoachmarkUnverified, 0);
    }
  };

  // TRANSACTION
  const [dataTransaksi, setDataTransaksi] = useState([]);

  const getDataTransaksi = async () => {
    const res = await getPaginate(API_URL.get_transaction, 10, 1, "");
    if (res?.status === 200) {
      setDataTransaksi(res?.data?.data);
    }
  };

  // TOP CATEGORY
  const carouselRef = useRef();
  const [dataTopCategory, setDataTopCategory] = useState([]);

  const getTopProduct = async () => {
    const res = await getAll(API_URL.top_product);
    if (res?.status === 200) {
      setDataTopCategory(res?.data?.data);
    }
  };

  const handleNextSlide = () => {
    carouselRef.current.next();
  };

  const handlePrevSlide = () => {
    carouselRef.current.previous();
  };

  // PENDING TOPUP
  const [dataTopUp, setDataTopUp] = useState([]);

  const carouselTopupRef = useRef();

  const handleNextSlideTopup = () => {
    carouselTopupRef.current.next();
  };

  const handlePrevSlideTopup = () => {
    carouselTopupRef.current.previous();
  };

  const getPendingTopup = async () => {
    const res = await getAll(API_URL.top_up + "/all", "?status=1");
    if (res?.status === 200) {
      setDataTopUp(res?.data?.data);
    }
  };

  useEffect(() => {
    if (dataProfile) {
      handleCheckMilestone();
    }
    document.title = "Dashboard - " + themeConfig.appname;
  }, [dataProfile]);

  const webSocketBalance = useWebSocketBalance();
  const [userBalance, setUserBalance] = useState(0);

  const getUserBalance = () => {
    setUserBalance(webSocketBalance || dataProfile?.saldo);
  };

  useEffect(() => {
    getUserBalance();
  }, [webSocketBalance]);

  return (
    <>
      <TopBar
        pageGreeting={
          dataProfile?.name !== "" || dataProfile?.name !== null
            ? `Selamat Datang, ${dataProfile?.name}👋`
            : "Selamat Datang👋"
        }
        rightButton={
          <Link
            to={"/notification"}
            type="text"
            shape="circle"
            className="vc-text-color-black-10 vc-mr-8 vc-text-color-dark-10"
          >
            <Notification set="curved" size={24} />
          </Link>
        }
        theme="purple"
      />
      <div className="vc-home-header">
        <div
          className="vc-bg-color-primary-1 vc-p-16 vc-mb-16 vc-pb-64"
          id="vc-amount-component"
        >
          <h5 className="vc-text-color-black-10 vc-mb-0">Saldo Saya</h5>
          <h4 className="vc-text-color-black-10 vc-mb-0">
            {userBalance ? (
              "Rp " + formatterNumber(userBalance)
            ) : (
              <i>Sedang memuat</i>
            )}
          </h4>
          {dataProfile?.kyc_verified === false &&
            dataProfile?.maximum_unverified_amount !== 0 && (
              <div>
                <i className="vc-text-color-black-10 vc-mb-0">
                  Limit Rp{" "}
                  {formatterNumber(dataProfile?.maximum_unverified_amount)},
                  Silahkan lengkapi berkas
                </i>
              </div>
            )}
        </div>

        <div className="vc-p-16 vc-card-menu" id="vc-shortcut-menu-component">
          <Card bodyStyle={{ padding: "12px" }} className="vc-p-8">
            <Row className="vc-m-0">
              <Col flex="1" className="vc-text-center">
                <Link to="/topup/form" className="vc-app-menu">
                  <div className="vc-header-menu ">
                    <Plus
                      set="curved"
                      className="vc-text-color-primary-1 vc-text-color-dark-primary-1"
                    />
                  </div>
                  <span className="vc-d-block vc-text-color-black-100 vc-text-color-dark-10">
                    Top Up Saldo
                  </span>
                </Link>
              </Col>
              <Col flex="1" className="vc-text-center">
                <Link to="/history-balance" className="vc-app-menu">
                  <div className="vc-header-menu ">
                    <Wallet
                      set="curved"
                      className="vc-text-color-primary-1 vc-text-color-dark-primary-1"
                    />
                  </div>
                  <span className="vc-d-block vc-text-color-black-100 vc-text-color-dark-10">
                    Riwayat Saldo
                  </span>
                </Link>
              </Col>
              <Col flex="1" className="vc-text-center">
                <Link to="/statistic" className="vc-app-menu">
                  <div className="vc-header-menu ">
                    <Graph
                      set="curved"
                      className="vc-text-color-primary-1 vc-text-color-dark-primary-1"
                    />
                  </div>
                  <span className="vc-d-block vc-text-color-black-100 vc-text-color-dark-10">
                    Statistik
                  </span>
                </Link>
              </Col>
              {dataProfile?.is_referral_feature && (
                <Col flex="1" className="vc-text-center">
                  <Link to="/referral/data" className="vc-app-menu">
                    <div className="vc-header-menu ">
                      <Star
                        set="curved"
                        className="vc-text-color-primary-1 vc-text-color-dark-primary-1"
                      />
                    </div>
                    <span className="vc-d-block vc-text-color-black-100 vc-text-color-dark-10">
                      Referral
                    </span>
                  </Link>
                </Col>
              )}
            </Row>
          </Card>
        </div>
      </div>

      <div className="vc-p-16 vc-pb-120">
        <Suspense fallback={<Skeleton active />}>
          {/* {dataProfile?.panel && dataProfile?.panel?.status === 1 ? (
            <Card
              className="vc-bg-color-primary-1 vc-mb-16"
              bodyStyle={{ padding: "16px" }}
            >
              <Row gutter={[16, 16]} align={"middle"}>
                <Col flex={"auto"}>
                  <h4 className="vc-mb-4 vc-text-color-black-0">
                    Pendafaran pembuatan websitemu sedang diproses
                  </h4>
                  <p className="vc-mb-0 vc-text-color-black-0">
                    Kami sedang melakukan verifikasi terhadap berkasmu, silahkan
                    tunggu maksimal 3 hari kerja
                  </p>
                </Col>
              </Row>
            </Card>
          ) : dataProfile?.panel &&
            dataProfile?.panel?.status === 2 &&
            moment().diff(dataProfile?.panel?.approved_at, "days") < 3 ? (
            <Card
              className="vc-bg-color-primary-1 vc-mb-16"
              bodyStyle={{ padding: "16px" }}
            >
              <Row gutter={[16, 16]} align={"middle"}>
                <Col flex={"auto"}>
                  <h4 className="vc-mb-4 vc-text-color-black-0">
                    Pendafaran pembuatan websitemu berhasil
                  </h4>
                  <p className="vc-mb-0 vc-text-color-black-0">
                    Silahkan buka panelmu sekarang
                  </p>
                </Col>
                <Col>
                  <Link to="/mitra-panel/status">
                    <a href={process.env.panel?.url} target="_blank">
                      <Button className="vc-bg-color-black-0 vc-text-color-primary-1 vc-text-color-hover-primary-1">
                        Buka Web Topup
                      </Button>
                    </a>
                  </Link>
                </Col>
              </Row>
            </Card>
          ) : dataProfile?.panel && dataProfile?.panel?.status === 3 ? (
            <Card
              className="vc-bg-color-primary-1 vc-mb-16"
              bodyStyle={{ padding: "16px" }}
            >
              <Row gutter={[16, 16]} align={"middle"}>
                <Col flex={"auto"}>
                  <h4 className="vc-mb-4 vc-text-color-black-0">
                    Maaf Pendafaran pembuatan websitemu ditolak
                  </h4>
                  <p className="vc-mb-0 vc-text-color-black-0">
                    Silahkan cek status penolakan dan ulangi proses penginputan
                    berkas
                  </p>
                </Col>
                <Col>
                  <Link to="/mitra-panel/status">
                    <Button className="vc-bg-color-black-0 vc-text-color-primary-1 vc-text-color-hover-primary-1">
                      Lihat Status
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          ) : (
            !dataProfile?.panel && (
              <Card
                className="vc-bg-color-primary-1 vc-mb-16"
                bodyStyle={{ padding: "16px" }}
              >
                <Row gutter={[16, 16]} align={"middle"}>
                  <Col flex={"auto"}>
                    <h4 className="vc-mb-4 vc-text-color-black-0">
                      Belum Punya Website Topup Game? Buat Websitemu Sekarang
                    </h4>
                    <p className="vc-mb-0 vc-text-color-black-0">
                      VCGamers menyediakan website topup game khusus customer
                      kamu, cukup siapkan nama store dan url websitemu
                    </p>
                  </Col>
                  <Col>
                    <Link to="/mitra-panel/register">
                      <Button className="vc-bg-color-black-0 vc-text-color-primary-1 vc-text-color-hover-primary-1">
                        Buat Website Sekarang
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            )
          )} */}

          {dataProfile?.kyc_verified === false &&
          dataProfile?.kyc_pending === false &&
          dataProfile?.kyc_reject === false ? (
            <Card
              bodyStyle={{ padding: "12px" }}
              className="vc-bg-color-primary-4 vc-mb-24"
            >
              <Row align={"middle"}>
                <Col span={18}>
                  <div className="vc-d-flex vc-align-items-center vc-text-color-primary-1">
                    <RiInformationLine size={24} className="vc-mr-8" />
                    <h5 className="vc-text-color-primary-1 vc-mb-0">
                      Lakukan Verifikasi Berkas untuk bertransaksi tanpa limit
                    </h5>
                  </div>
                </Col>
                <Col span={6} className="vc-text-right">
                  <Link to="/kyc-form">
                    <Button type="primary" className="vc-p-8">
                      Klik Disini
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          ) : dataProfile?.kyc_verified === false &&
            dataProfile?.kyc_pending === true &&
            dataProfile?.kyc_reject === false ? (
            <Card
              bodyStyle={{ padding: "12px" }}
              className="vc-bg-color-primary-4 vc-mb-24"
            >
              <div className="vc-d-flex">
                <div className="vc-d-flex vc-align-items-center vc-text-color-primary-1">
                  <RiInformationLine size={24} className="vc-mr-8" />
                  <h5 className="vc-text-color-primary-1 vc-mb-0">
                    Kami sedang melakukan proses pengecekan verifikasi berkas
                  </h5>
                </div>
              </div>
            </Card>
          ) : (
            dataProfile?.kyc_verified === false &&
            dataProfile?.kyc_pending === false &&
            dataProfile?.kyc_reject === true && (
              <Card
                bodyStyle={{ padding: "12px" }}
                className="vc-bg-color-danger-4 vc-mb-24"
              >
                <Row align={"middle"}>
                  <Col span={18}>
                    <div className="vc-d-flex vc-align-items-center vc-text-color-danger-1">
                      <RiInformationLine size={24} className="vc-mr-8" />
                      <h5 className="vc-text-color-danger-1 vc-mb-0">
                        Maaf verifikasimu ditolak, Silahkan cek detail
                      </h5>
                    </div>
                  </Col>
                  <Col span={6} className="vc-text-right">
                    <Link to="/kyc">
                      <Button
                        type="primary"
                        className="vc-bg-color-danger-1 vc-p-8"
                        danger
                      >
                        Lihat Detail
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            )
          )}

          {dataTopUp.length > 0 && (
            <>
              <div className="vc-d-flex vc-d-flex-between vc-mb-8">
                <h4>Selesaikan Pembayaranmu</h4>
                {dataTopUp.length > 4 && (
                  <div>
                    <Button
                      type="text"
                      icon={
                        <RiArrowLeftLine
                          size={20}
                          className="vc-text-color-black-60"
                        />
                      }
                      className="vc-mr-8"
                      onClick={handlePrevSlideTopup}
                    />
                    <Button
                      type="text"
                      icon={
                        <RiArrowRightLine
                          size={20}
                          className="vc-text-color-black-60"
                        />
                      }
                      onClick={handleNextSlideTopup}
                    />
                  </div>
                )}
              </div>

              <Carousel
                ref={carouselTopupRef}
                additionalTransfrom={1}
                arrows={false}
                centerMode={false}
                className="vc-w-100 vc-mb-24"
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass="vc-mr-16"
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1,
                    partialVisibilityGutter: 32,
                  },
                  mobile: {
                    breakpoint: {
                      max: 667,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 16,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 667,
                    },
                    items: 1,
                    partialVisibilityGutter: 16,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {dataTopUp?.map((item) => (
                  <Card className="vc-bg-color-primary-4 vc-border-none vc-mr-8">
                    <div className="vc-d-flex vc-d-flex-between">
                      <div>
                        Top Up Saldo
                        <h5>
                          {item.is_admin === true
                            ? "Rp " + formatterNumber(item.amount)
                            : "Rp" + formatterNumber(item.invoice_paid_amount)}
                        </h5>
                      </div>
                      <div>
                        <Link to={"/topup/payment?q=" + item.id}>
                          <Button type="primary">Detail</Button>
                        </Link>
                      </div>
                    </div>
                  </Card>
                ))}
              </Carousel>
            </>
          )}

          {isCompleteMilestone === true ? (
            <>
              {dataTopCategory.length > 0 && (
                <div id="vc-top-sale-product-component">
                  <div className="vc-d-flex vc-d-flex-between vc-mb-8">
                    <h4>Yang sering kamu beli 👜</h4>
                    {dataTopCategory.length > 4 && (
                      <div>
                        <Button
                          type="text"
                          icon={
                            <RiArrowLeftLine
                              size={20}
                              className="vc-text-color-black-60"
                            />
                          }
                          className="vc-mr-8"
                          onClick={handlePrevSlide}
                        />
                        <Button
                          type="text"
                          icon={
                            <RiArrowRightLine
                              size={20}
                              className="vc-text-color-black-60"
                            />
                          }
                          onClick={handleNextSlide}
                        />
                      </div>
                    )}
                  </div>

                  <Carousel
                    ref={carouselRef}
                    additionalTransfrom={1}
                    arrows={false}
                    centerMode={false}
                    className="vc-w-100 vc-mb-24"
                    containerClass="container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 4,
                        partialVisibilityGutter: 32,
                      },
                      mobile: {
                        breakpoint: {
                          max: 667,
                          min: 0,
                        },
                        items: 4,
                        partialVisibilityGutter: 16,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 667,
                        },
                        items: 4,
                        partialVisibilityGutter: 16,
                      },
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                  >
                    {dataTopCategory?.map((item) => (
                      <SingleProduct
                        key={"top-" + item.parent_category_id}
                        id={item.parent_category_id}
                        logo={item.image}
                        name={item.name}
                        className="vc-mr-8"
                      />
                    ))}
                  </Carousel>
                </div>
              )}

              <h4 className="vc-mb-16">Tools</h4>
              <Row
                gutter={[16, 16]}
                className="vc-mb-24"
                id="vc-tools-component"
              >
                <Col span={6} className="vc-text-center">
                  <Link to="/pricelist">
                    <Image
                      style={{ aspectRatio: " 1 / 1" }}
                      preview={false}
                      src={ImgMenuPricelist}
                      width={"72px"}
                      className="vc-mb-8"
                      alt={"Pricelist"}
                    />
                    <h6 className="vc-font-weight-600">Pricelist</h6>
                  </Link>
                </Col>
                <Col span={6} className="vc-text-center">
                  <Link to="/pricelist">
                    <Image
                      style={{ aspectRatio: " 1 / 1" }}
                      preview={false}
                      src={ImgMenuBestPrice}
                      width={"72px"}
                      className="vc-mb-8"
                      alt={"Produk Rekomendasi"}
                    />
                    <h6 className="vc-font-weight-600">Produk Rekomendasi</h6>
                  </Link>
                </Col>
                <Col span={6} className="vc-text-center">
                  <Link to="/set-selling-price/product">
                    <Image
                      style={{ aspectRatio: " 1 / 1" }}
                      preview={false}
                      src={ImgMenuAturHargaJual}
                      width={"72px"}
                      className="vc-mb-8"
                      alt={"Atur Harga Jual"}
                    />
                    <h6 className="vc-font-weight-600">Atur Harga Jual</h6>
                  </Link>
                </Col>
                <Col span={6} className="vc-text-center">
                  <Link to="/pembukuan">
                    <Image
                      style={{ aspectRatio: " 1 / 1" }}
                      preview={false}
                      src={ImgMenuPembukuan}
                      width={"72px"}
                      className="vc-mb-8"
                      alt={"Pembukuan"}
                    />
                    <h6 className="vc-font-weight-600">Pembukuan</h6>
                  </Link>
                </Col>
              </Row>

              {dataTransaksi.length > 0 && (
                <div id="vc-top-trx-component">
                  <h4 className="vc-mb-16">Transaksi Terakhir</h4>
                  {dataTransaksi?.map((item, index) => (
                    <SingleTransaction
                      index={index}
                      id={item.id}
                      key={item.id}
                      image={item.product_image}
                      date={item.date}
                      trx={item.code}
                      product={item.product_name}
                      sku={item.item_name}
                      price={item.selling_total}
                      status={item.status}
                      refund={item.refunded}
                      parentCategory={item.parent_category_id}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            isCompleteMilestone === false && (
              <div id="vc-milestone-component">
                <h4 className="vc-mb-16">Selesaikan Tahap Berikut</h4>
                <Card className="vc-mb-16" bodyStyle={{ padding: "16px" }}>
                  <Row align={"middle"} justify={"space-between"}>
                    <Col>
                      <h5 className="vc-mb-0">Pendaftaran Akun</h5>
                    </Col>
                    <Col>
                      <RiCheckboxCircleFill
                        size={24}
                        className="vc-text-color-success-1"
                      />
                    </Col>
                  </Row>
                </Card>
                <Card
                  className={
                    dataProfile?.has_pin === true
                      ? "vc-mb-16"
                      : "vc-mb-16 vc-cursor-pointer"
                  }
                  bodyStyle={{ padding: "16px" }}
                  onClick={() =>
                    dataProfile?.has_pin === false &&
                    history.push("/setting/pin")
                  }
                >
                  <Row align={"middle"} justify={"space-between"}>
                    <Col>
                      <h5 className="vc-mb-0">Buat PIN</h5>
                    </Col>
                    <Col>
                      {dataProfile?.has_pin === true ? (
                        <RiCheckboxCircleFill
                          size={24}
                          className="vc-text-color-success-1"
                        />
                      ) : (
                        <RiArrowRightLine
                          size={24}
                          className="vc-text-color-grey-1"
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
                <Card
                  className={
                    dataProfile?.kyc_verified === true
                      ? "vc-mb-16"
                      : "vc-mb-16 vc-cursor-pointer"
                  }
                  bodyStyle={{ padding: "16px" }}
                  onClick={() =>
                    dataProfile?.kyc_verified === false && history.push("/kyc")
                  }
                >
                  <Row align={"middle"} justify={"space-between"}>
                    <Col>
                      <h5 className="vc-mb-0">Lengkapi Berkas</h5>
                    </Col>
                    <Col>
                      {dataProfile?.kyc_verified === true ? (
                        <RiCheckboxCircleFill
                          size={24}
                          className="vc-text-color-success-1"
                        />
                      ) : (
                        <RiArrowRightLine
                          size={24}
                          className="vc-text-color-grey-1"
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
                <Card
                  className={
                    dataProfile?.has_topup === true
                      ? "vc-mb-16"
                      : "vc-mb-16 vc-cursor-pointer"
                  }
                  bodyStyle={{ padding: "16px" }}
                  onClick={() =>
                    dataProfile?.has_topup === false &&
                    history.push("/topup/form")
                  }
                >
                  <Row align={"middle"} justify={"space-between"}>
                    <Col>
                      <h5 className="vc-mb-0">Top Up Saldo Pertama</h5>
                    </Col>
                    <Col>
                      {dataProfile?.has_topup === true ? (
                        <RiCheckboxCircleFill
                          size={24}
                          className="vc-text-color-success-1"
                        />
                      ) : (
                        <RiArrowRightLine
                          size={24}
                          className="vc-text-color-grey-1"
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
                <Card
                  className={
                    dataProfile?.has_purchase === true
                      ? "vc-mb-16"
                      : "vc-mb-16 vc-cursor-pointer"
                  }
                  bodyStyle={{ padding: "16px" }}
                  onClick={() =>
                    dataProfile?.has_purchase === false &&
                    history.push("/product/data")
                  }
                >
                  <Row align={"middle"} justify={"space-between"}>
                    <Col>
                      <h5 className="vc-mb-0">Beli Produk Pertamamu</h5>
                    </Col>
                    <Col>
                      {dataProfile?.has_purchase === true ? (
                        <RiCheckboxCircleFill
                          size={24}
                          className="vc-text-color-success-1"
                        />
                      ) : (
                        <RiArrowRightLine
                          size={24}
                          className="vc-text-color-grey-1"
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
              </div>
            )
          )}
        </Suspense>
      </div>

      <BottomMenu active="home" id="vc-bottom-menu" />
    </>
  );
}
