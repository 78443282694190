import React, { Suspense, useEffect, useRef, useState } from "react";

import { Card, Col, Row, Button, Form, Skeleton } from "antd";

import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";

import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiCheckboxCircleFill,
  RiInformationLine,
} from "react-icons/ri";
import { getAll, getPaginate, post } from "../../../utils/service";
import { API_URL } from "../../../api/config";
import { Link, useHistory } from "react-router-dom";
import SingleTransaction from "../../../layout/components/wmr/transaction";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SingleProduct from "../../../layout/components/wmr/product";
import { formatterNumber } from "../../../utils/input-number";
import { useGetProfile } from "../../../UserProfileContext";
import DriverJS from "../../../utils/coachmark/web/driver";
import HomeCoachmark from "../../../utils/coachmark/web/home.json";
import HomeCoachmarkUnverified from "../../../utils/coachmark/web/home-unverified.json";
import moment from "moment";

export default function Home() {
  const [form] = Form.useForm();
  const history = useHistory();

  // Profile
  const dataProfile = useGetProfile();
  const [isCompleteMilestone, setIsCompleteMilestone] = useState(null);

  const handleCheckMilestone = () => {
    if (
      dataProfile?.has_pin === true &&
      // dataProfile?.kyc_verified === true &&
      dataProfile?.has_topup === true &&
      dataProfile?.has_purchase === true
    ) {
      DriverJS("dashboard", HomeCoachmark, 0);

      setIsCompleteMilestone(true);
      getDataTransaksi();
      getTopProduct();
      getPendingTopup();
    } else {
      setIsCompleteMilestone(false);
      DriverJS("dashboard", HomeCoachmarkUnverified, 0);
    }
  };

  // TRANSACTION
  const [dataTransaksi, setDataTransaksi] = useState([]);

  const getDataTransaksi = async () => {
    const res = await getPaginate(API_URL.get_transaction, 10, 1, "");
    if (res?.status === 200) {
      setDataTransaksi(res?.data?.data);
    }
  };

  // TOP CATEGORY
  const carouselRef = useRef();
  const [dataTopCategory, setDataTopCategory] = useState([]);

  const getTopProduct = async () => {
    const res = await getAll(API_URL.top_product);
    if (res?.status === 200) {
      setDataTopCategory(res?.data?.data);
    }
  };

  const handleNextSlide = () => {
    carouselRef.current.next();
  };

  const handlePrevSlide = () => {
    carouselRef.current.previous();
  };

  // PENDING TOPUP
  const [dataTopUp, setDataTopUp] = useState([]);

  const carouselTopupRef = useRef();

  const handleNextSlideTopup = () => {
    carouselTopupRef.current.next();
  };

  const handlePrevSlideTopup = () => {
    carouselTopupRef.current.previous();
  };

  const getPendingTopup = async () => {
    const res = await getAll(API_URL.top_up + "/all", "?status=1");
    if (res?.status === 200) {
      setDataTopUp(res?.data?.data);
    }
  };

  useEffect(() => {
    if (dataProfile) {
      handleCheckMilestone();
    }
  }, [dataProfile]);

  useEffect(() => {
    document.title = "Dashboard - " + themeConfig.appname;
  }, []);

  return (
    <>
      <PageTitle pageTitle="Dashboard" />

      <Suspense fallback={<Skeleton active />}>
        {/* {dataProfile?.panel && dataProfile?.panel?.status === 1 ? (
          <Card
            className="vc-bg-color-primary-1 vc-mb-16"
            bodyStyle={{ padding: "16px" }}
          >
            <Row gutter={[16, 16]} align={"middle"}>
              <Col flex={"auto"}>
                <h4 className="vc-mb-4 vc-text-color-black-0">
                  Pendafaran pembuatan websitemu sedang diproses
                </h4>
                <p className="vc-mb-0 vc-text-color-black-0">
                  Kami sedang melakukan verifikasi terhadap berkasmu, silahkan
                  tunggu maksimal 3 hari kerja
                </p>
              </Col>
            </Row>
          </Card>
        ) : dataProfile?.panel &&
          dataProfile?.panel?.status === 2 &&
          moment().diff(dataProfile?.panel?.approved_at, "days") < 3 ? (
          <Card
            className="vc-bg-color-primary-1 vc-mb-16"
            bodyStyle={{ padding: "16px" }}
          >
            <Row gutter={[16, 16]} align={"middle"}>
              <Col flex={"auto"}>
                <h4 className="vc-mb-4 vc-text-color-black-0">
                  Pendafaran pembuatan websitemu berhasil
                </h4>
                <p className="vc-mb-0 vc-text-color-black-0">
                  Silahkan buka panelmu sekarang
                </p>
              </Col>
              <Col>
                <Link to="/mitra-panel/status">
                  <a href={process.env.panel?.url} target="_blank">
                    <Button className="vc-bg-color-black-0 vc-text-color-primary-1 vc-text-color-hover-primary-1">
                      Buka Panel
                    </Button>
                  </a>
                </Link>
              </Col>
            </Row>
          </Card>
        ) : dataProfile?.panel && dataProfile?.panel?.status === 3 ? (
          <Card
            className="vc-bg-color-primary-1 vc-mb-16"
            bodyStyle={{ padding: "16px" }}
          >
            <Row gutter={[16, 16]} align={"middle"}>
              <Col flex={"auto"}>
                <h4 className="vc-mb-4 vc-text-color-black-0">
                  Maaf Pendafaran pembuatan websitemu ditolak
                </h4>
                <p className="vc-mb-0 vc-text-color-black-0">
                  Silahkan cek status penolakan dan ulangi proses penginputan
                  berkas
                </p>
              </Col>
              <Col>
                <Link to="/mitra-panel/status">
                  <Button className="vc-bg-color-black-0 vc-text-color-primary-1 vc-text-color-hover-primary-1">
                    Lihat Status
                  </Button>
                </Link>
              </Col>
            </Row>
          </Card>
        ) : (
          !dataProfile?.panel && (
            <Card
              className="vc-bg-color-primary-1 vc-mb-16"
              bodyStyle={{ padding: "16px" }}
            >
              <Row gutter={[16, 16]} align={"middle"}>
                <Col flex={"auto"}>
                  <h4 className="vc-mb-4 vc-text-color-black-0">
                    Belum Punya Website Topup Game? Buat Websitemu Sekarang
                  </h4>
                  <p className="vc-mb-0 vc-text-color-black-0">
                    VCGamers menyediakan website topup game khusus customer
                    kamu, cukup siapkan nama store dan url websitemu
                  </p>
                </Col>
                <Col>
                  <Link to="/mitra-panel/register">
                    <Button className="vc-bg-color-black-0 vc-text-color-primary-1 vc-text-color-hover-primary-1">
                      Buat Website Sekarang
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          )
        )} */}

        {dataProfile?.kyc_verified === false &&
        dataProfile?.kyc_pending === false &&
        dataProfile?.kyc_reject === false ? (
          <Card
            bodyStyle={{ padding: "12px" }}
            className="vc-bg-color-primary-4 vc-mb-24"
          >
            <Row align={"middle"}>
              <Col span={18}>
                <div className="vc-d-flex vc-align-items-center vc-text-color-primary-1">
                  <RiInformationLine size={24} className="vc-mr-8" />
                  <h5 className="vc-text-color-primary-1 vc-mb-0">
                    Lakukan Verifikasi Berkas untuk bertransaksi tanpa limit
                  </h5>
                </div>
              </Col>
              <Col span={6} className="vc-text-right">
                <Link to="/kyc-form">
                  <Button type="primary" className="vc-p-8">
                    Klik Disini
                  </Button>
                </Link>
              </Col>
            </Row>
          </Card>
        ) : dataProfile?.kyc_verified === false &&
          dataProfile?.kyc_pending === true &&
          dataProfile?.kyc_reject === false ? (
          <Card
            bodyStyle={{ padding: "12px" }}
            className="vc-bg-color-primary-4 vc-mb-24"
          >
            <div className="vc-d-flex">
              <div className="vc-d-flex vc-align-items-center vc-text-color-primary-1">
                <RiInformationLine size={24} className="vc-mr-8" />
                <h5 className="vc-text-color-primary-1 vc-mb-0">
                  Kami sedang melakukan proses pengecekan verifikasi berkas
                </h5>
              </div>
            </div>
          </Card>
        ) : (
          dataProfile?.kyc_verified === false &&
          dataProfile?.kyc_pending === false &&
          dataProfile?.kyc_reject === true && (
            <Card
              bodyStyle={{ padding: "12px" }}
              className="vc-bg-color-danger-4 vc-mb-24"
            >
              <Row align={"middle"}>
                <Col span={18}>
                  <div className="vc-d-flex vc-align-items-center vc-text-color-danger-1">
                    <RiInformationLine size={24} className="vc-mr-8" />
                    <h5 className="vc-text-color-danger-1 vc-mb-0">
                      Maaf verifikasimu ditolak, Silahkan cek detail
                    </h5>
                  </div>
                </Col>
                <Col span={6} className="vc-text-right">
                  <Link to="/kyc">
                    <Button
                      type="primary"
                      className="vc-bg-color-danger-1 vc-p-8"
                      danger
                    >
                      Lihat Detail
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          )
        )}

        {dataTopUp.length > 0 && (
          <>
            <div className="vc-d-flex vc-d-flex-between vc-mb-8">
              <h4>Selesaikan Pembayaranmu</h4>
              {dataTopUp.length > 4 && (
                <div>
                  <Button
                    type="text"
                    icon={
                      <RiArrowLeftLine
                        size={20}
                        className="vc-text-color-black-60"
                      />
                    }
                    className="vc-mr-8"
                    onClick={handlePrevSlideTopup}
                  />
                  <Button
                    type="text"
                    icon={
                      <RiArrowRightLine
                        size={20}
                        className="vc-text-color-black-60"
                      />
                    }
                    onClick={handleNextSlideTopup}
                  />
                </div>
              )}
            </div>

            <Carousel
              ref={carouselTopupRef}
              additionalTransfrom={1}
              arrows={false}
              centerMode={false}
              className="vc-w-100 vc-mb-24"
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass="vc-mr-16"
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 4,
                  partialVisibilityGutter: 32,
                },
                mobile: {
                  breakpoint: {
                    max: 667,
                    min: 0,
                  },
                  items: 4,
                  partialVisibilityGutter: 16,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 667,
                  },
                  items: 4,
                  partialVisibilityGutter: 16,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {dataTopUp?.map((item) => (
                <Card className="vc-bg-color-primary-4 vc-border-none vc-w-100 vc-mr-16">
                  <div className="vc-d-flex vc-d-flex-between vc-align-items-center">
                    <div>
                      Top Up Saldo
                      <h5 className="vc-text-color-black-100">
                        {item.is_admin === true
                          ? "Rp " + formatterNumber(item.amount)
                          : "Rp" + formatterNumber(item.invoice_paid_amount)}
                      </h5>
                    </div>
                    <div>
                      <Link to={"/topup/payment?q=" + item.id}>
                        <Button type="primary">Detail</Button>
                      </Link>
                    </div>
                  </div>
                </Card>
              ))}
            </Carousel>
          </>
        )}

        {isCompleteMilestone === true ? (
          <>
            {dataTopCategory.length > 0 && (
              <div id="vc-top-sale-product-component">
                <div className="vc-d-flex vc-d-flex-between vc-mb-8">
                  <h4>Yang sering kamu beli 👜</h4>
                  {dataTopCategory.length > 4 && (
                    <div>
                      <Button
                        type="text"
                        icon={
                          <RiArrowLeftLine
                            size={20}
                            className="vc-text-color-black-60"
                          />
                        }
                        className="vc-mr-8"
                        onClick={handlePrevSlide}
                      />
                      <Button
                        type="text"
                        icon={
                          <RiArrowRightLine
                            size={20}
                            className="vc-text-color-black-60"
                          />
                        }
                        onClick={handleNextSlide}
                      />
                    </div>
                  )}
                </div>

                <Carousel
                  ref={carouselRef}
                  additionalTransfrom={1}
                  arrows={false}
                  centerMode={false}
                  className="vc-w-100 vc-mb-24"
                  containerClass="container"
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite={false}
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1024,
                      },
                      items: 4,
                      partialVisibilityGutter: 32,
                    },
                    mobile: {
                      breakpoint: {
                        max: 667,
                        min: 0,
                      },
                      items: 4,
                      partialVisibilityGutter: 16,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 667,
                      },
                      items: 4,
                      partialVisibilityGutter: 16,
                    },
                  }}
                  rewind={false}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {dataTopCategory?.map((item) => (
                    <SingleProduct
                      key={"top-" + item.parent_category_id}
                      id={item.parent_category_id}
                      logo={item.image}
                      name={item.name}
                      className="vc-mr-8"
                    />
                  ))}
                </Carousel>
              </div>
            )}

            {dataTransaksi.length > 0 && (
              <div id="vc-top-trx-component">
                <h4 className="vc-mb-16">Transaksi Terakhir</h4>
                {dataTransaksi?.map((item, index) => (
                  <SingleTransaction
                    index={index}
                    id={item.id}
                    key={item.id}
                    image={item.product_image}
                    date={item.date}
                    trx={item.code}
                    product={item.product_name}
                    sku={item.item_name}
                    price={item.selling_total}
                    status={item.status}
                    refund={item.refunded}
                    parentCategory={item.parent_category_id}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          isCompleteMilestone === false && (
            <div id="vc-milestone-component">
              <h4 className="vc-mb-16">Selesaikan Tahap Berikut</h4>
              <Card className="vc-mb-16" bodyStyle={{ padding: "16px" }}>
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <h5 className="vc-mb-0">Pendaftaran Akun</h5>
                  </Col>
                  <Col>
                    <RiCheckboxCircleFill
                      size={24}
                      className="vc-text-color-success-1"
                    />
                  </Col>
                </Row>
              </Card>
              <Card
                className={
                  dataProfile?.has_pin === true
                    ? "vc-mb-16"
                    : "vc-mb-16 vc-cursor-pointer"
                }
                bodyStyle={{ padding: "16px" }}
                onClick={() =>
                  dataProfile?.has_pin === false && history.push("/setting/pin")
                }
              >
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <h5 className="vc-mb-0">Buat PIN</h5>
                  </Col>
                  <Col>
                    {dataProfile?.has_pin === true ? (
                      <RiCheckboxCircleFill
                        size={24}
                        className="vc-text-color-success-1"
                      />
                    ) : (
                      <RiArrowRightLine
                        size={24}
                        className="vc-text-color-grey-1"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
              <Card
                className={
                  dataProfile?.kyc_verified === true
                    ? "vc-mb-16"
                    : "vc-mb-16 vc-cursor-pointer"
                }
                bodyStyle={{ padding: "16px" }}
                onClick={() =>
                  dataProfile?.kyc_verified === false && history.push("/kyc")
                }
              >
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <h5 className="vc-mb-0">Lengkapi Berkas</h5>
                  </Col>
                  <Col>
                    {dataProfile?.kyc_verified === true ? (
                      <RiCheckboxCircleFill
                        size={24}
                        className="vc-text-color-success-1"
                      />
                    ) : (
                      <RiArrowRightLine
                        size={24}
                        className="vc-text-color-grey-1"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
              <Card
                className={
                  dataProfile?.has_topup === true
                    ? "vc-mb-16"
                    : "vc-mb-16 vc-cursor-pointer"
                }
                bodyStyle={{ padding: "16px" }}
                onClick={() =>
                  dataProfile?.has_topup === false &&
                  history.push("/topup/form")
                }
              >
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <h5 className="vc-mb-0">Top Up Saldo Pertama</h5>
                  </Col>
                  <Col>
                    {dataProfile?.has_topup === true ? (
                      <RiCheckboxCircleFill
                        size={24}
                        className="vc-text-color-success-1"
                      />
                    ) : (
                      <RiArrowRightLine
                        size={24}
                        className="vc-text-color-grey-1"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
              <Card
                className={
                  dataProfile?.has_purchase === true
                    ? "vc-mb-16"
                    : "vc-mb-16 vc-cursor-pointer"
                }
                bodyStyle={{ padding: "16px" }}
                onClick={() =>
                  dataProfile?.has_purchase === false &&
                  history.push("/product/data")
                }
              >
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <h5 className="vc-mb-0">Beli Produk Pertamamu</h5>
                  </Col>
                  <Col>
                    {dataProfile?.has_purchase === true ? (
                      <RiCheckboxCircleFill
                        size={24}
                        className="vc-text-color-success-1"
                      />
                    ) : (
                      <RiArrowRightLine
                        size={24}
                        className="vc-text-color-grey-1"
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </div>
          )
        )}
      </Suspense>
    </>
  );
}
