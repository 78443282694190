import { RiWhatsappLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import themeConfig from "../../../configs/themeConfig";

export default function FloatingWhatsapp() {
  return (
    <a
      href={"https://wa.me/" + themeConfig.whatsapp}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <div className="vc-whatsapp-parent" role="button" tabIndex={-1}>
        <RiWhatsappLine size={40} />
      </div>
    </a>
  );
}
