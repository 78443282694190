import React from "react";

import { Card, Col, Image, Row } from "antd";
import { Link } from "react-router-dom";
import "moment/locale/id";
import defaultProduct from "../../../../assets/images/empty-product.png";

export default function SingleProduct(props) {
  const additionalClass = props.className || "";
  return (
    <Link to={"/transaction-form?q=" + props.id}>
      <Card
        bodyStyle={{ padding: "12px" }}
        className={"vc-h-100 " + additionalClass}
      >
        <Row gutter={[12, 12]} align="center" justify={"center"}>
          <Col span={24} lg={10}>
            {props.logo !== "" ? (
              <Image
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={props.logo}
                className="vc-border-radius"
                width={"100%"}
                alt={props.name}
              />
            ) : (
              <Image
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={defaultProduct}
                className="vc-border-radius"
                width={"100%"}
                alt={props.name}
              />
            )}
          </Col>
          <Col span={24} lg={14} className="vc-d-flex vc-d-flex-full-center">
            <div className="">
              <h5 className="vc-product-title vc-font-weight-600 vc-mb-0 vc-text-center">
                {props.name}
              </h5>

              {props.isNew === true && (
                <div className="vc-d-flex vc-align-items-center">
                  <small className="vc-text-color-danger-1 vc-mb-0 vc-mr-8">
                    NEW 🔥
                  </small>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </Link>
  );
}
