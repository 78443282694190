import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Badge, Menu } from "antd";

import navigation from "../../../../navigation/mitra";
import navigationPanel from "../../../../navigation/panel";
import { API_URL } from "../../../../api/config";
import { getAll } from "../../../../utils/service";
import { useGetProfile } from "../../../../UserProfileContext";

const { SubMenu } = Menu;

export default function MenuItem(props) {
  const { onClose } = props;

  // Redux
  const customise = useSelector((state) => state.customise);

  const [ticketData, setTicketData] = useState(0);

  const getTicketSummary = async () => {
    const res = await getAll(API_URL.ticket + "/indicator");
    if (res?.status === 200) {
      setTicketData(res?.data?.data);
    }
  };

  useEffect(() => {
    getTicketSummary();
  }, []);

  // Profile
  const dataProfile = useGetProfile();

  // Location
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // Menu
  const splitLocationUrl =
    splitLocation[splitLocation.length - 2] +
    "/" +
    splitLocation[splitLocation.length - 1];

  const getSidebarType = () => {
    let sidebarMenu = [];
    let navMenu = null;

    // if (localStorage.getItem("vcg-sidebar-type") === "panel") {
    //   navMenu = navigationPanel;
    // } else {
    //   navMenu = navigation;
    // }

    if (location.pathname.startsWith("/panel")) {
      navMenu = navigationPanel;
    } else {
      navMenu = navigation;
    }

    sidebarMenu = navMenu.map((item, index) => {
      if (item.header) {
        return (
          <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>
        );
      }

      if (item.children) {
        return (
          <SubMenu key={item.id} icon={item.icon} title={item.title}>
            {item.children.map((childrens, index) => {
              if (!childrens.children) {
                const childrenNavLink = childrens.navLink.split("/");

                return (
                  // Level 2
                  <Menu.Item
                    key={childrens.id}
                    className={
                      splitLocationUrl ===
                      childrenNavLink[childrenNavLink.length - 2] +
                        "/" +
                        childrenNavLink[childrenNavLink.length - 1]
                        ? "ant-menu-item-selected"
                        : "ant-menu-item-selected-in-active"
                    }
                    onClick={onClose}
                  >
                    <Link to={childrens.navLink}>{childrens.title}</Link>
                  </Menu.Item>
                );
              } else {
                return (
                  // Level 3
                  <SubMenu key={childrens.id} title={childrens.title}>
                    {childrens.children.map((childItem, index) => {
                      const childrenItemLink = childItem.navLink.split("/");

                      return (
                        <Menu.Item
                          key={childItem.id}
                          className={
                            splitLocationUrl ===
                            childrenItemLink[childrenItemLink.length - 2] +
                              "/" +
                              childrenItemLink[childrenItemLink.length - 1]
                              ? "ant-menu-item-selected"
                              : "ant-menu-item-selected-in-active"
                          }
                          onClick={onClose}
                        >
                          <Link to={childItem.navLink}>{childItem.title}</Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              }
            })}
          </SubMenu>
        );
      } else {
        const itemNavLink = item.navLink.split("/");

        if (!item.checkTier || dataProfile?.is_referral_feature === true) {
          return (
            // Level 1
            <Menu.Item
              key={item.id}
              icon={item.icon}
              onClick={onClose}
              className={
                splitLocation[splitLocation.length - 2] +
                  "/" +
                  splitLocation[splitLocation.length - 1] ===
                itemNavLink[itemNavLink.length - 2] +
                  "/" +
                  itemNavLink[itemNavLink.length - 1]
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-selected-in-active"
              }
            >
              {item.indicator ? (
                <>
                  <div className="vc-d-flex vc-d-flex-between vc-align-items-center">
                    <Link to={item.navLink}>{item.title}</Link>

                    {item.indicator === "ticket-indicator" && (
                      <Badge count={ticketData} className="vc-ml-8" />
                    )}
                  </div>
                </>
              ) : (
                <Link to={item.navLink}>{item.title}</Link>
              )}
            </Menu.Item>
          );
        }
      }
    });

    return sidebarMenu;
  };

  let menuItem = getSidebarType();

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[
        splitLocation.length === 5
          ? splitLocation[splitLocation.length - 3]
          : null,
        splitLocation[splitLocation.length - 2],
      ]}
      theme={customise.theme == "light" ? "light" : "dark"}
      style={{
        minHeight:
          dataProfile &&
          dataProfile.panel &&
          (dataProfile?.panel?.status === 2) !== null &&
          "calc(100vh - 179px)",
        maxHeight:
          dataProfile &&
          dataProfile.panel !== null &&
          dataProfile?.panel?.status === 2 &&
          "calc(100vh - 179px)",
      }}
    >
      {menuItem}
    </Menu>
  );
}
