import React from "react";

import { Button, Card, Col, Divider, Image, Row, Typography } from "antd";
import defaultProduct from "../../../../assets/images/empty-product.png";
import { Link } from "react-router-dom";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import moment from "moment";
import "moment/locale/id";
import { formatterNumber } from "../../../../utils/input-number";

const { Paragraph } = Typography;

export default function SingleTransaction(props) {
  const {
    index = 0,
    id = "",
    image = "",
    trx = "",
    product = "",
    sku = "",
    date = "",
    price = 0,
    status = 0,
    refund = 0,
    parentCategory = null,
  } = props;

  return (
    <Card bodyStyle={{ padding: "12px" }} className="vc-mb-16">
      <Link to={"/detail-transaction?q=" + id} id={"detail-" + index}>
        <Row gutter={[12, 12]} align={"middle"} wrap={false}>
          <Col flex="none">
            {image !== "" ? (
              <Image
                id="response-product-image"
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={image}
                className="vc-border-radius"
                width={"56px"}
                alt={product}
              />
            ) : (
              <Image
                id="response-product-image"
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={defaultProduct}
                className="vc-border-radius"
                width={"56px"}
                alt={product}
              />
            )}
          </Col>
          <Col flex="auto">
            <Row justify={"space-between"}>
              <Col>
                <Paragraph
                  id="response-code"
                  className="vc-mb-0 vc-text-color-primary-1 vc-font-weight-500"
                  style={{ fontSize: "12px" }}
                  ellipsis={{
                    rows: 1,
                    expandable: false,
                  }}
                >
                  {trx}
                </Paragraph>
              </Col>
              <Col>
                {(() => {
                  if (status === 1) {
                    return (
                      <span
                        className="vc-text-color-warning-1"
                        id="response-status"
                      >
                        Pending
                      </span>
                    );
                  } else if (status === 2) {
                    return (
                      <span
                        className="vc-text-color-success-1"
                        id="response-status"
                      >
                        Berhasil
                      </span>
                    );
                  } else if (status === 3) {
                    return (
                      <span
                        className="vc-text-color-black-60"
                        id="response-status"
                      >
                        Batal
                      </span>
                    );
                  } else if (status === 4 && refund === 1) {
                    return (
                      <span
                        className="vc-text-color-primary-1"
                        id="response-status"
                      >
                        Refund
                      </span>
                    );
                  } else if (status === 4 && refund === 2) {
                    return (
                      <span
                        className="vc-text-color-primary-1"
                        id="response-status"
                      >
                        {/* Refunded */}
                        Refund
                      </span>
                    );
                  } else {
                    return (
                      <span
                        className="vc-text-color-danger-1"
                        id="response-status"
                      >
                        Error
                      </span>
                    );
                  }
                })()}
              </Col>
            </Row>
            <Paragraph
              ellipsis={{
                rows: 1,
                expandable: false,
              }}
              className="vc-mb-0 vc-text-color-dark-10 vc-font-weight-500 "
              id="response-product-name"
            >
              {product}
            </Paragraph>
            <Row align={"middle"} gutter={[4, 4]} wrap={false}>
              <Col className="vc-text-color-black-60" flex={"1 1 1"}>
                <Paragraph
                  ellipsis={{
                    rows: 1,
                    expandable: false,
                  }}
                  className="vc-mb-0 vc-text-color-dark-10 vc-font-weight-500 "
                  id="response-product-name"
                >
                  {sku}
                </Paragraph>
              </Col>
              <Col
                className="vc-d-flex-center vc-text-color-black-60"
                flex={"none"}
              >
                <RiCheckboxBlankCircleFill style={{ fontSize: "4px" }} />
              </Col>
              <Col
                className="vc-text-color-black-60"
                id="response-date"
                flex={"1 1 160px"}
              >
                {moment(date).locale("id").format("DD MMM YYYY HH:mm:ss")}
              </Col>
            </Row>
          </Col>
        </Row>
      </Link>

      <Divider className="vc-p-0 vc-my-8" />
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <p className="vc-mb-0 vc-text-color-black-60">Harga Produk</p>
          <h5
            className="vc-mb-0 vc-text-color-dark-10"
            id="response-selling-total"
          >
            Rp {formatterNumber(price)}
          </h5>
        </Col>
        <Col>
          <Link
            id={"btn-reorder-" + id}
            key={"btn-reorder-" + id}
            to={"/transaction-form?q=" + parentCategory + "&trx=" + id}
          >
            <Button type="primary" size="small" className="vc-py-8 vc-px-16">
              Beli Lagi
            </Button>
          </Link>
        </Col>
      </Row>
    </Card>
  );
}
