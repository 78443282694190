export default function getInitials(name) {
  // Split the name into words
  const words = name.split(" ");

  if (words.length >= 2) {
    const firstCharacterOfFirstWord = words[0].charAt(0).toUpperCase();
    const firstCharacterOfSecondWord = words[1].charAt(0).toUpperCase();
    const initials = firstCharacterOfFirstWord + firstCharacterOfSecondWord;
    return initials;
  } else if (words.length === 1) {
    // If there's only one word, return the first character of that word
    return words[0].charAt(0).toUpperCase();
  }

  return "Error";
}
